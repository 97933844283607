<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/images/faurg-logo-horizontal-short.png" class="logo-image">
    </a>
  </div>
</div>

<div class="header-container">
  <nb-action *ngIf="showBankDataInformations">
    <div (click)="selectAccount()" class="select-account">
      <nb-icon icon="wallet" pack="fa" style="font-size: 1.2rem; margin-right: 15px;"></nb-icon>
      <span class="account-action" *ngIf="existSelectedAccount">{{bankLabel}} | <b>Ag:</b> {{agencyLabel}} | <b>Cc:</b> {{acccountLabel}}</span>
      <span class="account-action" *ngIf="!existSelectedAccount">(Selecione uma conta)</span>
    </div>
  </nb-action>
  <nb-actions size="small">


    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
