export const environment = {
  production: false,
  baseUrl: "https://dev.back.financeiro.faurg.org.br",
  toastPosition: "top-right", //TOP_RIGHT="top-right",TOP_LEFT="top-left",BOTTOM_RIGHT="bottom-right",BOTTOM_LEFT="bottom-left"
  pages: {
    selects: {
      recordsPerPage: 5
    },
    user: {
      recordsPerPage: 10
    },
    account: {
      recordsPerPage: 10
    }
  },
  tableSettings: {
    noDataMessage: ""
  },
  financial: {
    banks: [
      {
        code: "001",
        settings: {
          transaction: {
            showStatusTransaction: true,
            allowSync: true,
            importOFX: false,
          }
        }
      },
      {
        code: "041",
        settings: {
          transaction: {
            showStatusTransaction: false,
            allowSync: false,
            importOFX: true,
          }
        }
      }
    ]
  }
};
