import { Component, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { UserAccountSelectTableSettings } from './useraccount-select.tablesettings';
import { LocalDataSource } from 'ng2-smart-table';
import { NbDialogRef } from '@nebular/theme';
import { Account, AccountData } from '../../../../@core/data/account';
import { ToastService } from '../../../../@core/utils/toast/toast.service';
import { ProfileEnum, User } from '../../../../@core/data/user';
import { ContextData } from '../../../../@core/data/context';
import { LocalStorageData } from '../../../../@core/data/local-storage';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { BaseResponse } from '../../../../@core/data/base-response';
import { Bank } from '../../../../@core/data/bank';

@Component({
  selector: 'ngx-useraccount-select',
  templateUrl: './useraccount-select.component.html',
  styleUrls: ['./useraccount-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UseraccountSelectComponent implements OnInit {
  settings = UserAccountSelectTableSettings;
  source: LocalDataSource = new LocalDataSource();
  pagination: any = { currentPage: 1, perPage: this.settings.pager.perPage }
  total: number=0
  accounts: Account<Bank>[]
  user: User
  account: Account<Bank>;
  isLoading: boolean = true;
  showFilter:boolean=false;
  filter:Account<Bank> = {
    bank:{}
  };

  constructor(
    @Optional() protected ref: NbDialogRef<UseraccountSelectComponent>,
    private service: AccountData,
    private toast: ToastService,
    private context: ContextData,
    private localStorage: LocalStorageData,
    private router: Router) {
      this.isLoading = true
  }

  ngOnInit(): void {
    this.user = this.context.get()

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  selectAccount(account: Account<Bank>) {
    this.localStorage.set('account', account)
    this.router.navigate([this.router.url])
    this.ref.close()
  }

  onClickRow(event) {
    this.selectAccount(event.data)
  }

  onList() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          const userId = (this.user.profile == ProfileEnum.Administrador ? null : this.user.id)
          return this.service.listByUser(userId, this.pagination, this.filter)
        }),
        map(data => {
          this.isLoading = false;
          return data;
        }),
        catchError(err => {
          this.isLoading = false;
          return err;
        })
      )
      .subscribe(
        (result: BaseResponse<Account<Bank>[]>) => {
          this.total = result.count
          this.accounts = result.data
          this.source.load(this.accounts)
        },
        (result) => {
          if (result.status_code != 404)
            this.toast.error()
        }
      )
  }

  onFilter(){
    if (this.showFilter)
      this.showFilter=false
    else
      this.showFilter=true
  }

  close() {
    this.ref.close()
  }

  onClear() {
    this.showFilter = false
    this.filter = {}
    this.onList()
  }

}
