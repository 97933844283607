import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-spinner-faurg',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() show:boolean = false

  constructor() { }
}
