<ngx-spinner bdColor="rgba(247,249,252,0.8)" size="medium" color="#8f9bb3" type="ball-scale-multiple" [fullScreen]="true"></ngx-spinner>

<div class="row" style="justify-content: center;">
  <img src="assets/images/faurg-logo-horizontal.png" class="logo">
</div><br>

<p class="sub-title">Informe a sua nova senha</p>

<nb-alert *ngIf="showDefaultMessages && !submitted" outline="danger" role="alert">
  Não foi possível salvar a sua senha, tente informar uma senha que atenda a estes requisitos:
  <ul class="alert-message-list">
    <li> - Comprimento mínimo de 8 caracteres</li>
    <li> - Evite senhas comuns</li>
    <li> - Faça o uso de caracteres alfanuméricos e especiais</li>
  </ul>
</nb-alert>

<form (ngSubmit)="resetPass()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-password">Nova senha:</label>
    <input nbInput
           [(ngModel)]="changePassword.new_password1"
           #new_password1="ngModel"
           type="password"
           id="input-new_password1"
           name="new_password1"
           class="first"
           placeholder="Nova senha"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="new_password1.dirty ? (new_password1.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="true">
    <ng-container *ngIf="new_password1.invalid && new_password1.touched">
      <p class="caption status-danger">
        Senha é obrigatória
      </p>
    </ng-container>
  </div>

  <div class="form-group">
    <label class="label" for="input-re-password">Confirme a nova senha:</label>
    <input nbInput
           [(ngModel)]="changePassword.new_password2"
           #new_password2="ngModel"
           id="input-new_password2"
           name="new_password2"
           type="password"
           class="last"
           placeholder="Confirme a nova senha"
           fullWidth
           fieldSize="large"
           [status]="new_password2.dirty ? (new_password2.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="true">
    <ng-container *ngIf="new_password2.invalid && new_password2.touched">
    <p class="caption status-danger">
      Confirmação de senha é obrigatório
    </p>
  </ng-container>
  </div>

  <button nbButton
          status="primary"
          fullWidth
          size="large"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
    {{(source === 'reset') ? 'Alterar' : 'Criar'}} senha
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Voltar para a tela de login</a></p>
</section>
