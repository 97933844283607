import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService,
    private router:Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    var routeSplit = state.url.split('/')
    var parentRoute = `/${routeSplit[1]}/${routeSplit[2]}/`
    var response = true

    this.authService.isAuthenticated()
      .subscribe(authenticated => {
        if (!authenticated) {
          response = false
          this.router.navigate(['auth/login']);
        }
        // else {
        //   this.router.navigate(['/pages/404']);
        // }
      }
    );

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        if (token.getPayload().profile != 'ADM') {
          if (parentRoute == '/pages/access/' || parentRoute == '/pages/bank-data/'){
            response = false
            // this.router.navigate(['/']);
            this.router.navigate(['/pages/404']);
          }
        }
      }
    });

    return response
  }
}
