import { Observable } from "rxjs";
import { Account } from "./account";
import { BaseResponse } from "./base-response";
import { Bank } from "./bank";

export interface DailyConsolidation {
    id?:number;
    posting_date?:string;
    balance?:number;
}

export interface DailyConsolidationFilter {
    account?:Account<Bank>;
    period?:DailyConsolidationPeriodFilter;
}

export interface DailyConsolidationPeriodFilter {
  start?:Date;
  end?:Date;
}

export abstract class DailyConsolidationData {
    abstract list(filter:DailyConsolidationFilter, pagination:any);
    abstract getMonths(filter:DailyConsolidationFilter);
    abstract sync(filter:DailyConsolidationFilter):Observable<BaseResponse<any>>;
    abstract lastSync(filter:DailyConsolidationFilter):Observable<BaseResponse<any>>;
}
