<nb-card>
  <nb-card-header>{{title}}</nb-card-header>
  <nb-card-body>
    {{description}}
    <div *ngIf="records.length > 0">
      <ul *ngFor="let record of records">
        <li>{{record}}</li>
      </ul>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="yes_button" nbButton status="success" (click)="submit(true)">Confirmar</button>
    <button class="no_button" nbButton status="basic" (click)="submit(false)">Cancelar</button>
  </nb-card-footer>
</nb-card>
