import { Observable } from "rxjs";
import { BaseResponse } from "./base-response";
import { Bank } from "./bank";

export interface Account<T> {
  id?:number;
  agency?:string;
  bank_account?:string;
  project_number?:string;
  project_name?:string;
  project_agreement?:string;
  bank?:T;
}

export abstract class AccountData {
  abstract list(filter:Account<number>, pagination:any): Observable<BaseResponse<Account<Bank>[]>>
  abstract insert(account:Account<number>)
  abstract update(account:Account<number>)
  abstract get(id): Observable<BaseResponse<Account<Bank>>>
  abstract delete(id)
  abstract listByUser(userId:number, pagination?:any, filter?:Account<Bank>): Observable<BaseResponse<Account<Bank>[]>>
}
