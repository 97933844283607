import { Injectable } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { ContextData } from '../data/context';
import { ProfileEnum, User } from '../data/user';

@Injectable()
export class ContextService extends ContextData {
  constructor(private authService: NbAuthService) { super() }

  get():User {
    var user:User;
    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        user = {
          id: token.getPayload().user_id,
          email: token.getPayload().email,
          name: token.getPayload().name,
          profile: token.getPayload().profile
        }
      }
    });
    return user;
  }

  isAdm():boolean {
    const user = this.get()
    return (user.profile == ProfileEnum.Administrador ? true : false)
  }

  isOnlyCredit():boolean {
    const user = this.get()
    return (user.profile == ProfileEnum.Credito ? true : false)
  }
}
