import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Observable } from 'rxjs';
import { ToastService } from '../../../@core/utils/toast/toast.service';
import { GetExtensionFileByMimeType } from '../../../@core/utils/common/file-utils';

@Component({
  selector: 'ngx-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {

  @Input() allowedMimeTypes: string[] = [];
  @Input() minSize: number;
  @Input() maxSize: number;
  selectedFiles?: FileList;

  constructor(
    protected ref: NbDialogRef<UploaderComponent>,
    private toast: ToastService,
  ) { }

  submit(value: boolean) {
    if (!value) {
      this.ref.close({value, undefined})
      return
    }

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (!this.validate(file))
        return

      this.ref.close({value, file})
    } else {
      this.showNoSelectedFileError()
    }
  }

  validate(file: File): boolean {
    if (!file) {
      this.showNoSelectedFileError()
      return false
    }

    var extension = file.name.split('.')[1]

    if (this.allowedMimeTypes) {
      var allowedFile: boolean = false
      this.allowedMimeTypes.forEach(m => {
        if (m.toLowerCase() === extension.toLowerCase())
          allowedFile = true
      })

      if (!allowedFile) {
        let extensions: string = ""
        this.allowedMimeTypes.forEach(function (elem, idx, array) {
          if (idx === array.length - 1) {
            extensions += elem.toUpperCase()
          } else {
            extensions += elem.toUpperCase() + " | "
          }
        });

        let errorMsg = ""
        if (this.allowedMimeTypes.length > 1)
          errorMsg = `Selecione um arquivo com as seguintes extensões: ${extensions}`
        else
          errorMsg = `Selecione um arquivo com a seguinte extensão: ${extensions}`

        this.toast.errorTitleMsg("Extensão incorreta", errorMsg)
        return false
      }
    }

    return true
  }

  showNoSelectedFileError() {
    this.toast.errorTitleMsg("Selecione um arquivo", "Nenhum arquivo foi selecionado")
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
}
