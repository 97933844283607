import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent{

  @Input() title: string;
  @Input() description:string;
  @Input() records: string[] = [];

  constructor(protected ref: NbDialogRef<DialogComponent>) {}

  submit(value:boolean){
    this.ref.close(value)
  }

}
