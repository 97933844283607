import { Injectable } from '@angular/core';
import { User, UserData } from '../data/user';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse } from '../data/base-response';

@Injectable()
export class UserService extends UserData {
  private baseUrl = environment.baseUrl;

  constructor(private http:HttpClient) { super() }

  insert(user:User){
    return this.http.post(`${this.baseUrl}/api/user/`,user)
  }

  update(user:User) {
    return this.http.put(`${this.baseUrl}/api/user/${user.id}/`,user)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/user/${id}/`,id)
  }

  list(filter:User, pagination:any): Observable<BaseResponse<User[]>> {
    var params = new HttpParams()
    params = params.set('page', pagination.currentPage)
    params = params.set('page_size', pagination.perPage)

    if (filter.name)
      params = params.set('name', filter.name)

    if (filter.profile)
      params = params.set('profile', filter.profile)

    return this.http.get<BaseResponse<User[]>>(`${this.baseUrl}/api/user/`, { params })
  }

  get(id): Observable<BaseResponse<User>> {
    return this.http.get<BaseResponse<User>>(`${this.baseUrl}/api/user/${id}/`)
  }
}
