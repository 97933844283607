import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse } from '../data/base-response';
import { Bank, BankData } from '../data/bank';

@Injectable()
export class BankService extends BankData {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { super() }

  list(filter: Bank, pagination: any): Observable<BaseResponse<Bank[]>> {
    var params = new HttpParams()
    params = params.set('page', pagination.currentPage)
    params = params.set('page_size', pagination.perPage)

    if (filter.code)
      params = params.set('code', filter.code)
    if (filter.id)
      params = params.set('id', filter.id)
    if (filter.name)
      params = params.set('name', filter.name)

    return this.http.get<BaseResponse<Bank[]>>(`${this.baseUrl}/api/bank/`, { params })
  }
}
