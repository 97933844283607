import { Component } from '@angular/core';
import { AuthService } from '../../@core/service/auth.service';
import { ToastService } from '../../@core/utils/toast/toast.service';
import { ComponentStatus } from '../../@core/utils/enum/component-status.enum';
import { BaseResponse } from '../../@core/data/base-response';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent {
  request:any = {email:""}
  submitted:boolean=false
  errors=[]

  constructor(
    private service:AuthService,
    private toast:ToastService,
    private router:Router,
    private spinner:NgxSpinnerService
  ) { }

  onRequest(form:NgForm) {
    this.submitted = true;
    this.spinner.show()

    this.service.requestPassword(this.request.email)
    .subscribe(
      () => {
        this.toast.show("Nova senha solicitada",
            "Solicitação realizada com sucesso! Verifique o seu e-mail.",
            ComponentStatus.Success)

        setTimeout(() => {
          this.spinner.hide()
          return this.router.navigateByUrl('/auth/login');
        }, 1000);
      },
      (result:BaseResponse<null>) => {
        this.spinner.hide()
        this.submitted = false;

        if (result.messages?.filter(m => m.stack == 'email').length) {
          form.controls.email.setErrors({invalid:true})
          return
        }
        this.errors = result.messages
      }
    )
  }
}
