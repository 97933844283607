<div style="display: flex; justify-content: center; margin-top: 15px;" *ngIf="((currentPage === 1 && pages.length > 1) || currentPage > 1) && countPages">
  <nav class="ng2-smart-pagination-nav ng-star-inserted">
    <ul class="ng2-smart-pagination pagination">
      <li class="ng2-smart-page-item page-item li-pager" *ngIf="lot > 1">
        <a aria-label="Last" class="ng2-smart-page-link a-pager page-link" style="cursor: pointer; color: #007bff;"
          (click)="onLot(-1)">
          <span aria-hidden="true" class="span-pager">«</span>
        </a>
      </li>
      <li class="ng2-smart-page-item page-item li-pager" *ngIf="currentPage > 1">
        <a aria-label="Last" class="ng2-smart-page-link a-pager page-link" style="cursor: pointer; color: #007bff;"
          *ngIf="currentPage > 1" (click)="onPageClick(currentPage-1)">
          <span aria-hidden="true" class="span-pager">&lt;</span>
        </a>
      </li>
      <li class="ng2-smart-page-item page-item li-pager ng-star-inserted" *ngFor="let page of pages"
        [ngClass]="{'active' : currentPage === page}">
        <a class="ng2-smart-page-link page-link a-pager ng-star-inserted span-pager"
          style="display: flex;align-items: center;justify-content: center;cursor: pointer; color: #007bff;"
          (click)="onPageClick(page)" *ngIf="currentPage !== page">
          <span class="span-pager">
            {{page}}
          </span>
        </a>
        <span class="ng2-smart-page-link a-pager page-link ng-star-inserted span-pager" *ngIf="currentPage === page"
          style="display: flex;align-items: center;justify-content: center;">
          {{page}}
        </span>
      </li>
      <li class="ng2-smart-page-item li-pager page-item" *ngIf="currentPage !== countPages">
        <a aria-label="Next" class="ng2-smart-page-link a-pager page-link page-link-next"
          style="display: flex;align-items: center;justify-content: center;cursor: pointer; color: #007bff;"
          *ngIf="currentPage !== countPages" (click)="onPageClick(currentPage+1)">
          <span aria-hidden="true" class="span-pager">&gt;</span>
        </a>
      </li>
      <li class="ng2-smart-page-item li-pager page-item" *ngIf="lot < lotSize">
        <a aria-label="Last" class="ng2-smart-page-link a-pager page-link" style="cursor: pointer; color: #007bff;"
          (click)="onLot(1)">
          <span aria-hidden="true" class="span-pager">»</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
