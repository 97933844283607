import { Injectable } from '@angular/core';
import { Account, AccountData } from '../data/account';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse } from '../data/base-response';
import { AccountMock } from '../mock/account-mock';
import { Bank } from '../data/bank';

@Injectable()
export class AccountService extends AccountData {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { super() }

  insert(account: Account<number>) {
    return this.http.post(`${this.baseUrl}/api/account/`, account)
  }

  update(account: Account<number>) {
    return this.http.put(`${this.baseUrl}/api/account/${account.id}/`, account)
  }

  delete(id) {
    return this.http.delete(`${this.baseUrl}/api/account/${id}/`, id)
  }

  get(id): Observable<BaseResponse<Account<Bank>>> {
    return this.http.get<BaseResponse<Account<Bank>>>(`${this.baseUrl}/api/account/${id}/`)
  }

  list(filter: Account<number>, pagination: any): Observable<BaseResponse<Account<Bank>[]>> {
    var params = new HttpParams()
    params = params.set('page', pagination.currentPage)
    params = params.set('page_size', pagination.perPage)

    if (filter.agency)
      params = params.set('agency', filter.agency)
    if (filter.bank_account)
      params = params.set('bank_account', filter.bank_account)
    if (filter.project_number)
      params = params.set('project_number', filter.project_number)
    if (filter.bank)
      params = params.set('bank', filter.bank)

    return this.http.get<BaseResponse<Account<Bank>[]>>(`${this.baseUrl}/api/account/`, { params })
  }

  listByUser(userId: number, pagination: any, filter: Account<Bank>): Observable<BaseResponse<Account<Bank>[]>> {
    var params = new HttpParams()

    if (userId) {
      params = params.set('user', userId)
    }

    if (filter.agency)
      params = params.set('agency', filter.agency)

    if (filter.bank_account)
      params = params.set('bank_account', filter.bank_account)

    if (pagination) {
      params = params.set('page', pagination.currentPage)
      params = params.set('page_size', pagination.perPage)
    }

    return this.http.get<BaseResponse<Account<Bank>[]>>(`${this.baseUrl}/api/account/`, { params })

  }
}
