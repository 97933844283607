import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseResponse } from '../data/base-response';
import { Transaction, TransactionData, TransactionFilter } from '../data/transaction';
import { Observable } from 'rxjs';
import { toBackendDateFormat } from '../utils/common/datetime-utils';
import { FileExtension } from '../utils/enum/file-extension.enum';

@Injectable()
export class TransactionService extends TransactionData {
  private baseUrl = environment.baseUrl;

  constructor(private http:HttpClient) { super() }

  list(filter:TransactionFilter, pagination:any): Observable<BaseResponse<Transaction[]>> {
    var params = new HttpParams()
    params = params.set('page', pagination.currentPage)
    params = params.set('page_size', pagination.perPage)
    params = params.set('account_id', filter.account.id)
    params = params.set('start_posting_date', toBackendDateFormat(filter.period.start))
    params = params.set('end_posting_date', toBackendDateFormat(filter.period.end))

    if (filter.transaction_type)
      params = params.set('transaction_type', filter.transaction_type)

    if (filter.max_amount)
      params = params.set('max_amount', filter.max_amount)

    if (filter.min_amount)
      params = params.set('min_amount', filter.min_amount)

    return this.http.get<BaseResponse<Transaction[]>>(`${this.baseUrl}/api/transaction/bank_statement/`, { params })
  }

  getMonths(filter:TransactionFilter): Observable<BaseResponse<any>> {
    var params = new HttpParams()
    params = params.set('account_id', filter.account.id)
    return this.http.get<BaseResponse<any>>(`${this.baseUrl}/api/transaction/month/`, { params })
  }

  sync(filter:TransactionFilter):Observable<BaseResponse<any>> {
    var params = new HttpParams()
    params = params.set('account_id', filter.account.id)
    return this.http.get<BaseResponse<any>>(`${this.baseUrl}/api/transaction/sync/`, { params })
  }

  lastSync(filter:TransactionFilter):Observable<BaseResponse<any>> {
    var params = new HttpParams()
    params = params.set('account_id', filter.account.id)
    return this.http.get<BaseResponse<any>>(`${this.baseUrl}/api/daily/transaction/status/`, { params })
  }

  export(filter:TransactionFilter, pagination:any, extension:FileExtension):Observable<BaseResponse<any>> {
    var params = new HttpParams()
    params = params.set('page', pagination.currentPage)
    params = params.set('page_size', pagination.perPage)
    params = params.set('account_id', filter.account.id)
    params = params.set('start_posting_date', toBackendDateFormat(filter.period.start))
    params = params.set('end_posting_date', toBackendDateFormat(filter.period.end))

    if (filter.transaction_type)
      params = params.set('transaction_type', filter.transaction_type)

    if (filter.max_amount)
      params = params.set('max_amount', filter.max_amount)

    if (filter.min_amount)
      params = params.set('min_amount', filter.min_amount)

    return this.http.get<BaseResponse<any>>(`${this.baseUrl}/api/transaction/export/${extension}/`, { params })
  }

  uploadOfx(file:File):Observable<BaseResponse<any>> {
    const formData = new FormData();
    formData.append('file', file);
    // const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post<BaseResponse<any>>(`${this.baseUrl}/api/ofx/upload/`, formData)
  }
}
