import { ChangeDetectorRef, Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthJWTToken, NbAuthResult, NbAuthService } from '@nebular/auth';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LocalStorageData } from '../../@core/data/local-storage';
import { Account } from '../../@core/data/account';
import { CommonData } from '../../@core/data/common';
import { environment } from '../../../environments/environment';
import { Bank } from '../../@core/data/bank';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  showPassword = false;
  scriptElement: HTMLScriptElement;

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private _el: ElementRef,
    private _renderer: Renderer2,
    private spinner:NgxSpinnerService,
    private localStorage:LocalStorageData,
    private commonData:CommonData
  ) {
    this.strategy = 'email'
    this.showMessages = {
      success: true,
      error: true,
    }

    this.scriptElement = document.createElement("script")
    this.scriptElement.innerHTML = "document.getElementById(\"input-email\").addEventListener(\"focusout\", () => {document.getElementById(\"input-password\").focus()});"
    const original = this._el.nativeElement as HTMLElement;
    this._renderer.appendChild(original, this.scriptElement)
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.spinner.show()
          return this.service.authenticate(this.strategy, this.user)
        }),
        map(data => {
          this.spinner.hide()
          return data;
        }),
        catchError(err => {
          return err;
        })
      )
      .subscribe((result: NbAuthResult) => {
        if (result.getResponse()?.messages && result.getResponse().messages.find((message) => message.stack ===  "non_field_errors")) {
          this.errors = ["O seu usuário não possui contas associadas"]
          this.submitted = false;
          return
        }

        if (!result.isSuccess()) {
          this.errors = ["Login/senha informados estão incorretos"]
          this.submitted = false;
        }
        this.service.onTokenChange()
          .subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
              var redirect: string

          if (token.getPayload().profile == 'ADM') {
            redirect = "/pages/access/user-list"
          } else {
            redirect = "/pages/transaction/transaction-list"
          }

          this.getVersion()

          this.genericRules()

          this.router.navigateByUrl(redirect);
        }
      });

        this.cd.detectChanges();
      });
  }

  getVersion() {
    this.commonData.getVersion().subscribe((result) => {
      const production = environment.production
      const version = result.data.find(v => v.environment === ((production) ? "production" : "homolog"))
      this.localStorage.set('version',version)
    })
  }

  genericRules() {
    const account:Account<Bank> = this.localStorage.get('account')
    if (account && !account.bank?.id)
      this.localStorage.remove('account')
  }
}
