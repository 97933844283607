<style>
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }
</style>

<div id="nb-global-spinner" style="
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:1003;
        background: #ffffff;
        overflow:hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        opacity: 0.5;
    " *ngIf="show">
    <div style="
        height:200px;
        width:200px;
        -webkit-animation: spin 2s infinite linear;
    ">
      <img src="../../../assets/images/faurg-logo.png" width="300px" />
    </div>
  </div>
