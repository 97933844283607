import { environment } from "../../../../../environments/environment";


export const UserAccountSelectTableSettings = {
  pager: {
    perPage: environment.pages.selects.recordsPerPage,
    display:false
  },
  mode: 'external',
  hideSubHeader: true,
  selectedRowIndex: -1,
  actions: {
    columnTitle: "",
    add: false,
    edit: false,
    delete: false,
    position:'left'
  },
  rowClassFunction: (row) =>{
    return 'cursor-pointer'
  },
  noDataMessage: environment.tableSettings.noDataMessage,
  columns: {
    id: {
      title: 'Id',
      hide: true
    },
    bank: {
      title: 'Banco',
      valuePrepareFunction :(cell, row) =>{
        return row.bank.name;
      }
    },
    agency: {
      title: 'Agência',
    },
    bank_account: {
      title: 'Conta',
    },
  },
};
