<ngx-spinner bdColor="rgba(247,249,252,0.8)" size="medium" color="#8f9bb3" type="ball-scale-multiple" [fullScreen]="true"></ngx-spinner>

<div class="row" style="justify-content: center;">
  <img src="assets/images/faurg-logo-horizontal.png" class="logo">
</div><br>

<!-- <h1 id="title" class="title">Esqueci minha senha</h1> -->
<p class="sub-title">Um link de mudança de senha será enviado para o e-mail informado abaixo</p>


<form (ngSubmit)="onRequest(form)" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="labelEmail" for="input-email">Informe o seu endereço de e-mail:</label>
    <input nbInput
           [(ngModel)]="request.email"
           #email="ngModel"
           id="input-email"
           name="email"
           pattern=".+@.+\..+"
           placeholder="E-mail"
           autofocus
           fullWidth
           fieldSize="large"
           [required]="true">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        E-mail é obrigatório
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        E-mail inválido
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.invalid">
        E-mail inválido
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [class.btn-pulse]="submitted"
          [disabled]="submitted || !form.valid">
    Solicitar uma nova senha
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Voltar para o a tela de login</a></p>
</section>
