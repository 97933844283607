import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../@core/utils/toast/toast.service';
import 'rxjs/add/operator/filter';
import { AuthService } from '../../@core/service/auth.service';
import { ComponentStatus } from '../../@core/utils/enum/component-status.enum';
import { ChangePassword } from '../../@core/data/change-password';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  errors=[]
  submitted: boolean = false;
  changePassword:ChangePassword = {}
  showDefaultMessages:boolean = false
  source:string;

  constructor(
    private route: ActivatedRoute,
    private toast:ToastService,
    private service:AuthService,
    protected router: Router,
    private spinner:NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
    .filter(params => params.token)
    .subscribe(params => {
      this.changePassword.token = params.token;
    })

    this.route.queryParams
    .filter(params => params.source)
    .subscribe(params => {
      this.source = params.source;
    })
  }

  resetPass() {
    this.showDefaultMessages = false
    this.spinner.show()

    if(this.changePassword.new_password1 === this.changePassword.new_password2) {
      this.submitted = true;
      this.service.resetPassword(this.changePassword)
      .subscribe(
        () => {
          this.toast.show("Senha modificada", "Senha modificada com sucesso! Redirecionando para a tela de login...",ComponentStatus.Success)
          setTimeout(() => {
            this.spinner.hide()
            return this.router.navigateByUrl('/auth/login');
          }, 1000);
        },
        (result) => {
          this.spinner.hide()
          this.submitted = false;
          if (result.status_code == 400) {
            this.showDefaultMessages = true
          }
        }
      )
    } else {
      this.spinner.hide()
      this.toast.show("Senha inválida", "As senhas digitadas não coincidem", ComponentStatus.Danger)
    }
  }
}



