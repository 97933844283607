import { Observable } from "rxjs";
import { BaseResponse } from "./base-response";
import { Account } from "./account";
import { FileExtension } from "../utils/enum/file-extension.enum";
import { Bank } from "./bank";

export interface Transaction {
  id?:number;
  posting_date?:Date;
  identifier_number?:string;
  description?:string;
  amount?:number;
  transaction_type?:string;
  additional_information?:string;
  account_id?:number;
  origin_agency_code?:number;
  lot_number?:number;
  historical_code?:string;
}

export interface TransactionFilter {
  account?:Account<Bank>;
  transaction_type?:string;
  min_amount?:number;
  max_amount?:number;
  period?:TransactionPeriodFilter;
}

export interface TransactionPeriodFilter {
  start?:Date;
  end?:Date;
}

export abstract class TransactionData {
  abstract list(filter:TransactionFilter, pagination:any): Observable<BaseResponse<Transaction[]>>;
  abstract getMonths(filter:TransactionFilter);
  abstract sync(filter:TransactionFilter):Observable<BaseResponse<any>>;
  abstract lastSync(filter:TransactionFilter):Observable<BaseResponse<any>>;
  abstract export(filter:TransactionFilter, pagination:any, extension:FileExtension):Observable<BaseResponse<any>>;
  abstract uploadOfx(file:File):Observable<BaseResponse<any>>;
}
