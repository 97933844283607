<nb-card>
  <nb-card-header>Upload</nb-card-header>
  <nb-card-body>


    <div class="row">
      <div class="col-8">
        <label class="btn btn-default p-0">
          <input type="file" (change)="selectFile($event)" />
        </label>
      </div>
    </div>

  </nb-card-body>
  <nb-card-footer>
    <button class="yes_button" nbButton status="success" (click)="submit(true)">Confirmar</button>
    <button class="no_button" nbButton status="basic" (click)="submit(false)">Cancelar</button>
  </nb-card-footer>
</nb-card>
