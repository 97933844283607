import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BaseResponse } from "../data/base-response";
import { DailyConsolidation, DailyConsolidationData, DailyConsolidationFilter } from "../data/daily-consolidation";
import { toBackendDateFormat } from "../utils/common/datetime-utils";

@Injectable()
export class DailyConsolidationService extends DailyConsolidationData {
  private baseUrl = environment.baseUrl;

  constructor(private http:HttpClient) { super() }

  list(filter:DailyConsolidationFilter, pagination:any): Observable<BaseResponse<DailyConsolidation[]>> {
    var params = new HttpParams()
    params = params.set('page', pagination.currentPage)
    params = params.set('page_size', pagination.perPage)
    params = params.set('account_id', filter.account.id)
    params = params.set('start_posting_date', toBackendDateFormat(filter.period.start))
    params = params.set('end_posting_date', toBackendDateFormat(filter.period.end))
    return this.http.get<BaseResponse<DailyConsolidation[]>>(`${this.baseUrl}/api/daily/consolidation/`, { params })
  }

  getMonths(filter:DailyConsolidationFilter): Observable<BaseResponse<any>> {
    var params = new HttpParams()
    params = params.set('account_id', filter.account.id)
    return this.http.get<BaseResponse<any>>(`${this.baseUrl}/api/consolidation/month/`, { params })
  }

  sync(filter:DailyConsolidationFilter):Observable<BaseResponse<any>> {
    var params = new HttpParams()
    params = params.set('account_id', filter.account.id)
    return this.http.get<BaseResponse<any>>(`${this.baseUrl}/api/transaction/sync/`, { params })
  }

  lastSync(filter:DailyConsolidationFilter):Observable<BaseResponse<any>> {
    var params = new HttpParams()
    params = params.set('account_id', filter.account.id)
    return this.http.get<BaseResponse<any>>(`${this.baseUrl}/api/daily/transaction/status/`, { params })
  }

}
