import { Observable } from "rxjs";
import { BaseResponse } from "./base-response";

export interface CreditSummary {
  transactions?:CreditSummaryTransaction[];
  total_credit_all_accounts?:number;
  posting_date?:string;
}

export interface CreditSummaryTransaction {
  agency?:string;
  account?:string;
  account_id?:number;
  credit_total?:number;
}

export interface CreditSummaryFilter {
  posting_date:Date;
  min_amount?:string;
  max_amount?:string;
}

export abstract class CreditSummaryData {
  abstract list(filter:CreditSummaryFilter): Observable<BaseResponse<CreditSummary>>;
}
