import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthJWTToken, NbAuthModule, NbDummyAuthStrategy, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { MockDataModule } from './mock/mock-data.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { environment } from '../../environments/environment';
import { AuthModule } from '../auth/auth.module';
import { AuthGuard } from '../auth/auth-guard.service';

//Datas
import { UserData } from './data/user';
import { AccountData } from './data/account';
import { ContextData } from './data/context';
import { CreditSummaryData } from './data/credit-summary';
import { DailyConsolidationData } from './data/daily-consolidation';
import { LocalStorageData } from './data/local-storage';
import { UserAccountData } from './data/user-account';
import { TransactionData } from './data/transaction';

//Services
import { UserService } from './service/user.service';
import { AccountService } from './service/account.service';
import { LocalStorageService } from './service/local-storage.service';
import { ContextService } from './service/context.service';
import { UserAccountService } from './service/user-account.service';
import { TransactionService } from './service/transaction.service';
import { DailyConsolidationService } from './service/daily-consolidation.service';
import { CreditSummaryService } from './service/credit-summary.service';
import { BankData } from './data/bank';
import { BankService } from './service/bank.service';
import { CommonData } from './data/common';
import { CommonService } from './service/common.service';

const DATA_SERVICES = [
  { provide: LocalStorageData, useClass: LocalStorageService },
  { provide: UserData, useClass: UserService },
  { provide: AccountData, useClass: AccountService },
  { provide: BankData, useClass: BankService },
  { provide: ContextData, useClass: ContextService },
  { provide: UserAccountData, useClass: UserAccountService },
  { provide: TransactionData, useClass: TransactionService },
  { provide: DailyConsolidationData, useClass: DailyConsolidationService },
  { provide: CreditSummaryData, useClass: CreditSummaryService },
  { provide: CommonData, useClass: CommonService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  AuthGuard,
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',

        token: {
          class: NbAuthJWTToken,
        },

        baseEndpoint: environment.baseUrl,
        // baseEndpoint: ' http://127.0.0.1:5000',
        login: {
          endpoint: '/api/auth/token/'
        },
        requestPass: {
          redirect: {
            success: '/auth/reset-password',
          },
        },
        resetPass: {
          redirect: {
            success: '/auth/login',
          },
        },
        errors: {
          key: 'data.errors',
        },
      }),
    ],
    forms: {
      login: {
        strategy: 'email',
        redirectDelay: 1000,
        showMessages: {
          success: true,
          error: true,
        },
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  }
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
    AuthModule
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
