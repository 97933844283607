import { Observable } from "rxjs";
import { BaseResponse } from "./base-response";

export interface User {
  id?:number;
  name?:string;
  email?:string;
  profile?:string;
  is_active?:boolean;
  status?:string;
}

export enum ProfileEnum {
  Administrador = 'ADM',
  DebitoCredito = 'DEC',
  Credito = 'CRE',
}

export abstract class UserData {
  abstract insert(user:User)
  abstract update(user:User)
  abstract list(filter:User, pagination:any): Observable<BaseResponse<User[]>>
  abstract get(id): Observable<BaseResponse<User>>
  abstract delete(id)
}
