import { Injectable } from '@angular/core';
import { AuthData } from '../data/auth';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChangePassword } from '../data/change-password';

@Injectable()
export class AuthService extends AuthData {
  private baseUrl = environment.baseUrl;

  constructor(private http:HttpClient) { super() }

  update(changePassword:ChangePassword) {
    return this.http.post(`${this.baseUrl}/api/auth/change/`,changePassword)
  }

  login(username:string, password:string): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('email', username)
        .set('password',password)
    }

    var response = this.http.post<any>(`${this.baseUrl}/api/auth/token/`,options)
    return response;
  }

  requestPassword(email:string){
    return this.http.post(`${this.baseUrl}/api/auth/request/`,{email})
  }

  resetPassword(changePassword:ChangePassword) {
    return this.http.post(`${this.baseUrl}/api/auth/reset/`,changePassword)
  }

}

