import { Observable } from "rxjs";
import { BaseResponse } from "./base-response";

export interface Version {
  commit?:string;
  environment?:string;
  name?:string;
  url?:string;
}

export abstract class CommonData {
  abstract getVersion(): Observable<BaseResponse<Version[]>>
}
