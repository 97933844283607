import { Observable } from "rxjs";
import { BaseResponse } from "./base-response";

export interface Bank {
  id?:number;
  code?:string;
  name?:string;
}

export abstract class BankData {
  abstract list(filter:Bank, pagination:any): Observable<BaseResponse<Bank[]>>
}
