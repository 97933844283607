<nb-card [nbSpinner]="isLoading" nbSpinnerSize="small" nbSpinnerStatus="primary">
  <nb-card-header>
    <div class="row show-grid flex-nowrap" style="white-space: nowrap;">
      <div class="col-md-8">
        <div>Contas</div>
      </div>
      <div class="col-md-4 action-menu">
        <nb-actions size="small">
          <nb-action>
            <div style="display: flex; flex-direction: column; width: 25px; align-self: center;">
              <div (click)="onFilter()" [ngClass]="(showFilter) ? 'nofilter' : 'filter'"></div>
            </div>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </nb-card-header>


  <nb-card-body>
    <div class="row">
      <nb-card *ngIf="showFilter" class="col-md-12">
        <nb-card-body>
          <form (ngSubmit)="onList()" #form="ngForm" aria-labelledby="title">
            <div>
              <div class="form-group col-sd-6">
                <label for="inputFirstName" class="label">Agência</label>
                <input nbInput fullWidth [(ngModel)]="filter.agency" #agency="ngModel" name="agency" id="input-agency"
                  placeholder="Agência">
              </div>
              <div class="form-group col-sd-6">
                <label for="inputFirstName" class="label">Conta</label>
                <input nbInput fullWidth [(ngModel)]="filter.bank_account" #bank_account="ngModel" name="bank_account"
                  id="input-bank_account" placeholder="Conta">
              </div>
            </div>
            <div class="row flex-nowrap" style="display: flex;justify-content: center;">
              <button type="submit" nbButton status="primary" class="button">Filtrar</button>
              <button type="reset" nbButton class="button" (click)="onClear(); form.resetForm();">Limpar</button>
            </div>
          </form>
        </nb-card-body>
      </nb-card>
    </div>

    <ng2-smart-table [settings]="settings" [source]="source" (userRowSelect)="onClickRow($event)">
    </ng2-smart-table>

    <ngx-pager [(currentPage)]="pagination.currentPage" (currentPageChange)="onList()" [perPage]="pagination.perPage"
      [total]="total" [size]="'small'"></ngx-pager>
  </nb-card-body>

  <nb-card-footer>
    <button type="button" nbButton class="button" (click)="close()">Fechar</button>
  </nb-card-footer>
</nb-card>
